<template>
    <div>
        <Navigation />

        <div class="panel">
            <h1>Profile page for  <b>{{currentUser.username}}</b></h1>

            <form class="input-form" @submit.prevent="handleLogin(this.user)">
                <div class="input-item">
                    <label class="input-label" for="">name</label>
                    <input class="input-input" type="text" placeholder="user name" v-model="user.username"  disabled>
                </div>
                <div class="input-item">
                    <label class="input-label" for="">email</label>
                    <input class="input-input" type="text" placeholder="email" v-model="user.email" >
                </div>
                <div class="separator"></div>
                <div class="input-item">
                    <label class="input-label" for="">current password</label>
                    <input class="input-input" type="text"  placeholder="Current Password" v-model="user.password" >
                </div>
                <div class="input-item">
                    <label class="input-label" for="">new password</label>
                    <input class="input-input" type="text"  placeholder="New Password" v-model="new_password" >
                </div>
                <div class="input-item">
                    <label class="input-label" for="">confirm password</label>
                    <input class="input-input" type="text"  placeholder="Confrm Password" v-model="confirm_password" >
                </div>
                <div class="separator"></div>
                <div class="input-item">
                    <label class="input-label" for="">Role(s)</label>
                    <input class="input-input" type="text" placeholder="role" v-model="user.roles" required>
                </div>

                <button type="submit" class="submit-btn">Apply</button>
            </form>
        </div>
    </div>
</template>


<script>
import Navigation from '@/components/V1/Navigation.vue'

export default {
    name: "Profile",
    components: { 
        Navigation 
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        loggedIn() {
        return this.$store.state.auth.status.loggedIn;
        },
    },    
    data(){
        return{
            user:{
                username: this.$store.state.auth.user.username,
                password:this.$store.state.auth.user.password,
                email:this.$store.state.auth.user.email,
                roles:this.$store.state.auth.user.roles
            },
            username:'',
            new_password:"",
            confirm_password:"",
            message:""
        }
    },
    methods: {
        handleLogin(user) {
            user;
        }
    }
} 
</script>


<style scoped>
h1{
    margin-bottom:3em;
}

h1>b{
    color:#ffad06;
    font-size:1em;
}


/******************************************************************************* */

.separator{
    position:relative;
    width:100%;
    margin-top:1.2em;
    border-bottom:#ffad06 solid 1px;
    margin-bottom:2em;
}

.input-form{
    position:relative;
    height:100%;
    padding-bottom:3em;
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    /* outline:red solid 1px; */
}

.input-item{
    position:relative;
    width:100%;
    display:flex;
    flex-wrap:wrap;
    justify-content: flex-start;
    align-items:center;
    /* outline:blue solid 1px; */
}

.input-label{
    position:relative;
    min-width:140px;
    max-width:200px;
    /* outline:green solid 1px; */
}

.input-input{
    position:relative;
    width:80%;
    background: transparent;
    border:none;
    border-bottom:grey solid 1px;
    /* outline:yellow solid 1px; */
}

.input-input::placeholder{
    font-style: italic;
}

/******************************************************************************* */

.submit-btn {
    width: 8em;
    display: block;
    margin-top:3em;
    margin-left: auto;
    margin-right:10em;
    padding: 10px 30px;
    cursor: pointer;
    background: linear-gradient(to right, #ffef10, #ffad06);
    outline: none;
    border-radius: 30px;
    border: 0;
    color:#000;
    /* outline:yellow solid 1px; */
}

</style>